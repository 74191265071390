var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function (t, n) {
  exports = n();
}(exports, function () {
  "use strict";

  var t = "month",
      n = "quarter";
  return function (e, i) {
    var r = i.prototype;

    r.quarter = function (t) {
      return this.$utils().u(t) ? Math.ceil((this.month() + 1) / 3) : this.month(this.month() % 3 + 3 * (t - 1));
    };

    var s = r.add;

    r.add = function (e, i) {
      return e = Number(e), this.$utils().p(i) === n ? this.add(3 * e, t) : s.bind(this || _global)(e, i);
    };

    var u = r.startOf;

    r.startOf = function (e, i) {
      var r = this.$utils(),
          s = !!r.u(i) || i;

      if (r.p(e) === n) {
        var o = this.quarter() - 1;
        return s ? this.month(3 * o).startOf(t).startOf("day") : this.month(3 * o + 2).endOf(t).endOf("day");
      }

      return u.bind(this || _global)(e, i);
    };
  };
});
export default exports;